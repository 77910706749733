import { SearchContactsParams } from '@/types/contacts-service.type';
import gql from 'graphql-tag';
import http from './http.service';

export default class ContactsService {
  /** Get contact list */
  public static async get() {
    return http.graphql({
      query: gql`
        query {
          contacts {
            id
            fullName
            email
            pobCity
            pobState
            pobCountry
            cases {
              id
              name
              caseType {
                id
                name
              }
            }
            user {
              id
              firstName
              middleName
              lastName
            }
          }
        }
      `,
    });
  }

  /** Get contacts relations */
  public static async relations(contactId: any) {
    return http.graphql({
      query: gql`
        query contactsRelations($contactId: Int) {
          contactsRelationed(contactId: $contactId) {
            relatedContact {
              id
              firstName
              lastName
              middleName
              email
              fullName
            }
          }
        }
      `,
      variables: { contactId },
    });
  }

  /** Get contact for fullName key */
  public static async searchContact(search: SearchContactsParams) {
    return http.graphql({
      query: gql`
        query searchContact($search: searchContact) {
          contacts(search: $search) {
            id
            fullName
            email
            pobCity
            pobState
            pobCountry
            cases {
              id
              name
              caseType {
                id
                name
              }
            }
            user {
              id
              firstName
              middleName
              lastName
            }
          }
        }
      `,
      variables: { search },
    });
  }
}
