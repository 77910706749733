<template>
  <div v-if="loading" class="custom-loading-content">
    <ASpin size="large" />
  </div>
</template>

<script lang="ts">
import { useGlobalProps } from '@/composables';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const { loading } = useGlobalProps();

    return { loading };
  },
});
</script>

<style lang="scss">
.custom-loading-content {
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000; // LOL, I'am sorry
}

.ant {
  // Customize loading
  &-spin {
    $spin: &;
    &-lg {
      #{$spin}-dot {
        font-size: 50px !important;

        i {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }
  }
}
</style>
