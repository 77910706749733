import { computed, reactive } from 'vue';

interface State {
  loading: boolean;
  paymentMethodSelected: string;
  form: object;
  user: object;
  states: Array<string>;
  tenantPictureBase64: string;
}

const state = reactive<State>({
  loading: false,
  paymentMethodSelected: '',
  form: {},
  user: {},
  states: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
    'DC',
    'AS',
    'GU',
    'MP',
    'PR',
    'UM',
    'VI',
    'AS',
    'GU',
    'MP',
    'PR',
    'UM',
    'VI',
  ],
  tenantPictureBase64: '',
});

export default function() {
  const loading = computed(() => state.loading);
  const paymentMethodSelected = computed(() => state.paymentMethodSelected);
  const form = computed(() => state.form);
  const user = computed(() => state.user);
  const states = computed(() => state.states);
  const tenantPictureBase64 = computed(() => state.tenantPictureBase64);

  const setLoading = (payload: boolean) => (state.loading = payload);

  const setPaymentMethodSelected = (payload: string) =>
    (state.paymentMethodSelected = payload);

  const setForm = (payload: object) => (state.form = payload);

  const setUser = (payload: object) => (state.user = payload);

  const setTenantPictureBase64 = (payload: string) =>
    (state.tenantPictureBase64 = payload);

  return {
    loading,
    paymentMethodSelected,
    form,
    user,
    states,
    tenantPictureBase64,
    setLoading,
    setPaymentMethodSelected,
    setForm,
    setUser,
    setTenantPictureBase64,
  };
}
