export default class {
  /** Capitalize text */
  public static capitalize(value: string) {
    return value.replace(/[a-z]/, v => v.toUpperCase());
  }

  /** Create custom debounce function */
  public static createDebounce() {
    let timeout: any = null;

    return function(callback: () => void, delay = 500) {
      clearTimeout(timeout);

      timeout = setTimeout(() => callback(), delay);
    };
  }
}
