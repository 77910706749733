import axios from 'axios';
import { print } from 'graphql/language/printer';
import { HTTP_TOKEN_HEADER } from '@/utils/constants';
import { useGlobalProps } from '@/composables';

function createInstance() {
  const { loading, setLoading } = useGlobalProps();

  /** Axios global config */
  const config = {
    baseURL: process.env.VUE_APP_API_URL,
  };

  /** Axios instance */
  const _axios = axios.create(config);

  const GRAPHQL_PROPS = ['query', 'mutation'];

  // Define custom functions
  _axios.setToken = token => {
    // Set Axios header
    _axios.defaults.headers.common[HTTP_TOKEN_HEADER] = token;
  };

  _axios.getToken = () => {
    return _axios.defaults.headers.common[HTTP_TOKEN_HEADER];
  };

  _axios.graphql = data => _axios.post('/', { ...data, isGraphql: true });

  /** Axios intercept request */
  _axios.interceptors.request.use(
    function(config) {
      // Show loading
      if (!loading.value) {
        setLoading(true);
      }

      // Using "graphql" method
      if (config.data.isGraphql) {
        delete config.data.isGraphql;

        for (const prop in config.data) {
          // Format query and mutation
          if (GRAPHQL_PROPS.includes(prop)) {
            config.data['query'] = print(config.data[prop]);
          }
        }
      }
      // Do something before request is sent
      return config;
    },
    function(error) {
      // Hide loading
      if (loading.value) {
        setLoading(false);
      }
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  _axios.interceptors.response.use(
    function(response) {
      // Hide loading
      if (loading.value) {
        setLoading(false);
      }
      // Do something with response data
      return response;
    },
    function(error) {
      // Hide loading
      if (loading.value) {
        setLoading(false);
      }
      // Do something with response error
      return Promise.reject(error);
    },
  );

  return _axios;
}

export default createInstance();
