import { Integration, Account } from '@/types/integrations.type';
import { computed, reactive } from 'vue';

interface State {
  integrations: Integration[];
  accounts: Account[];
}

const state = reactive<State>({
  integrations: [],
  accounts: [],
});

export default () => {
  const integrations = computed(() => state.integrations);
  const accounts = computed(() => state.accounts);

  const setIntegrations = (payload: Integration[]) => {
    state.integrations = payload;
  };

  const setAccounts = (payload: Account[]) => {
    state.accounts = payload;
  };

  const hasPrimaPayIntegration = computed(() => {
    if (!state.integrations || !state.integrations.length) {
      return false;
    }

    return state.integrations.some(integration => {
      return integration.prefix === 'primaPay' && integration.connected;
    });
  });

  return {
    integrations,
    accounts,
    hasPrimaPayIntegration,
    setIntegrations,
    setAccounts,
  };
};
