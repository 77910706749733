
import { defineComponent } from 'vue';
import Loading from '@/components/Loading.vue';
import { httpService } from '@/services';

export default defineComponent({
  name: 'App',
  data() {
    return {
      currentContact: {
        id: 0,
        caseId: 0,
      },
      primaPayAccountId: '',
      primaPayTrustAccountId: '',
    };
  },
  components: {
    Loading,
  },
  created() {
    // Token logic
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    /** Get current contact & current case */
    this.currentContact.id = Number(urlParams.get('contactId'))
      ? Number(urlParams.get('contactId'))
      : 0;
    this.currentContact.caseId = Number(urlParams.get('caseId'))
      ? Number(urlParams.get('caseId'))
      : 0;

    const primaPayAccountId = urlParams.get('primaPayAccountId');
    const primaPayTrustAccountId = urlParams.get('primaPayTrustAccountId');

    if (primaPayAccountId) this.primaPayAccountId = primaPayAccountId;
    else this.primaPayAccountId = '';

    if (primaPayTrustAccountId)
      this.primaPayTrustAccountId = primaPayTrustAccountId;
    else this.primaPayTrustAccountId = '';

    // Token passed, set to axios as header
    if (token) {
      // Set header to request header
      httpService.setToken(token);
    }
  },
});
