
import { useGlobalProps } from '@/composables';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const { loading } = useGlobalProps();

    return { loading };
  },
});
