import {
  IntegrationAccounts,
  Account,
  PrimaPayAccounts,
} from '@/types/integrations.type';
import gql from 'graphql-tag';
import http from './http.service';

export default class {
  public static async getDualAccounts(): Promise<IntegrationAccounts> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query {
            accounts {
              _id
              userId
              type
              accountName
              lexChargeAccountId
            }

            accountsLexCharge {
              merchantId
              accountName
              registerState
              type
            }
          }
        `,
      });

      return Promise.resolve({
        accounts: data.data.accounts,
        primaPayAccounts: data.data.accountsLexCharge,
      } as IntegrationAccounts);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async getAccounts(): Promise<Account[]> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query {
            accounts {
              _id
              userId
              type
              accountName
              lexChargeAccountId
            }
          }
        `,
      });
      return Promise.resolve(data.data.accounts);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async syncAccount(dataMatch: any): Promise<Account> {
    try {
      const { data } = await http.graphql<any>({
        mutation: gql`
          mutation matchAccount($data: syncMatchAccount!) {
            matchAccount(data: $data) {
              sync
              primaAccountId
              lexChargeAccountId
            }
          }
        `,
        variables: { data: dataMatch },
      });
      return Promise.resolve(data.data.matchAccount);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async deletePaymentMethod(
    contactId: number,
    paymentMethodId: string,
  ): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        mutation: gql`
          mutation paymentMethodContactDelete(
            $contactId: Int!
            $paymentMethodId: String!
          ) {
            paymentMethodContactDelete(
              contactId: $contactId
              paymentMethodId: $paymentMethodId
            ) {
              _id
              contactId
              lawPayPaymentMethod
              paymentMethods
            }
          }
        `,
        variables: { contactId, paymentMethodId },
      });
      return Promise.resolve(data.data.paymentMethodContactDelete);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Get lexCharge account details */
  public static async getDetailsPrimaPayAccount(
    merchantId: string,
  ): Promise<PrimaPayAccounts[]> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query getDetailsPrimaPayAccount($merchantId: String!) {
            accountDetailsLexCharge(merchantId: $merchantId) {
              merchantId
              accountName
              bankAccountNumber
              bankRoutingNumber
              bankAccountOwnerName
            }
          }
        `,
        variables: { merchantId },
      });
      return Promise.resolve(data.data.accountDetailsLexCharge);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public static async getHostedPaymentToken(id: string): Promise<any[]> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query getHostedPaymentToken($id: String!) {
            merchantLexCharge(id: $id) {
              hostedPaymentToken
            }
          }
        `,
        variables: { id },
      });
      return Promise.resolve(data.data.merchantLexCharge);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Get payment methods LexCharge */
  public static async getPaymentMethodsLexCharge(
    contactId: string,
    merchantId: string,
  ): Promise<any[]> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query getPaymentMethods($contactId: String!, $merchantId: String!) {
            methodsCustomerLexCharge(
              contactId: $contactId
              merchantId: $merchantId
            ) {
              id
              merchantId
              userId
              method
              cardType
              cardLastFour
              cardExp
              nickname
              bankName
              cardExpDatetime
              bankHolderType
              personName
              address1
              addressCity
              addressState
              addressZip
              addressCountry
              customer {
                id
                contactId
                firstname
                lastname
                address1
                addressCity
                addressCountry
                addressZip
                email
                phone
              }
            }
          }
        `,
        variables: { contactId, merchantId },
      });
      return Promise.resolve(data.data.methodsCustomerLexCharge);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Update customer, save payment method LexCharge */
  public static async savePaymentMethod(
    customerId: string,
    contactId: string,
    merchantId: string,
  ): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        mutation: gql`
          mutation updateCustomer(
            $customerId: String!
            $contactId: String!
            $merchantId: String!
          ) {
            updateCustomerLexCharge(
              customerId: $customerId
              contactId: $contactId
              merchantId: $merchantId
            ) {
              id
              contactId
            }
          }
        `,
        variables: { customerId, contactId, merchantId },
      });
      return Promise.resolve(data.data.updateCustomerLexCharge);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
