import { Router } from 'vue-router';
import { httpService, UserService } from '@/services';

export default function(router: Router) {
  router.beforeEach(async (to, _, next) => {
    if (to.name === 'Unauthorized' || to.name === 'NotFound') {
      return next();
    }

    if (to.meta.auth && !httpService.getToken()) {
      return next('/unauthorized');
    }

    const isValid = await UserService.isValidToken();

    if (!isValid) {
      return next('/unauthorized');
    }

    next();
  });

  router.afterEach(to => {
    if (to.query.token) {
      window.history.replaceState(null, '', window.location.pathname);
    }
  });
}
