import gql from 'graphql-tag';
import http from './http.service';

import { NewPaymentPlan } from '@/types/payment-form.type';

export default class PaymentPlanService {
  /** Create payment plan */
  public static async create(newPaymentPlan: NewPaymentPlan) {
    return http.graphql({
      mutation: gql`
        mutation createPaymentPlan($newPaymentPlan: newPaymentPlan!) {
          paymentPlanAdd(newPaymentPlan: $newPaymentPlan) {
            _id
            contact {
              fullName
              email
            }
            case {
              name
              caseTypeName
            }
            user {
              firstName
              lastName
              email
            }
            status
            description
          }
        }
      `,
      variables: { newPaymentPlan },
    });
  }

  /** Get payment plans of case */
  public static async getCase(caseId: number): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query getCasePaymentPlans($caseId: Int) {
            paymentPlans(caseId: $caseId) {
              _id
              contact {
                fullName
                email
              }
              case {
                name
                caseTypeName
              }
              user {
                firstName
                lastName
                email
              }
              status
              description
            }
          }
        `,
        variables: { caseId },
      });

      return Promise.resolve(data.data.paymentPlans);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Get payment plans with _id */
  public static async getId(_id: string): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query getCasePaymentPlans($_id: String) {
            paymentPlans(_id: $_id) {
              _id
              contact {
                fullName
                email
              }
              case {
                name
                caseTypeName
              }
              user {
                firstName
                lastName
                email
              }
              payingParty
              termsAndConditions
              status
              description
              bills {
                _id
                contactId
                caseId
                billTotal
                status
                issueDate
                dueDate
                currency
                ID
                discountNote
                invoiceNote
                subject
                purchaseOrder
                discount
                discountPercentage
                discountAmount
                invoiceSubTotal
                invoiceTotal
                tax
                taxAmount
                pendingPayment
              }
            }
          }
        `,
        variables: { _id },
      });

      return Promise.resolve(data.data.paymentPlans);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
