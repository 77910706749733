import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import guards from './guards';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/Index.vue'),
  },
  {
    path: '/integrations',
    name: 'Integrations',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "integrations" */ '@/views/Integrations.vue'),
  },
  {
    path: '/payment-form/:form',
    name: 'PaymentForm',
    meta: { auth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "payment-form" */ '@/views/PaymentForm.vue'),
  },
  {
    path: '/payment-plans/:caseId',
    name: 'PaymentPlans',
    meta: { auth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "payment-plnas" */ '@/views/PaymentPlans.vue'
      ),
  },
  {
    path: '/viewer-payment-plan/:paymentPlan',
    name: 'ViewerPaymentPlan',
    meta: { auth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "payment-plnas" */ '@/views/ViewerPaymentPlan.vue'
      ),
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () =>
      import(/* webpackChunkName: "unauthorized" */ '@/views/Unauthorized.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Execute guards
guards(router);

export default router;
