import { Integration } from '@/types/integrations.type';
import gql from 'graphql-tag';
import http from './http.service';

export default class UserService {
  /** Verify token */
  public static async isValidToken(): Promise<boolean> {
    try {
      await http.graphql({
        query: gql`
          query {
            whoAmI {
              id
            }
          }
        `,
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  /** Verify token */
  public static async get(): Promise<object> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query {
            whoAmI {
              id
              firstName
              middleName
              lastName
              email
              phone
              city
              address
              state
              country
              zipCode
              lawFirmName
              tenantCode
              termsAndConditions
              tenant {
                logoUrl
              }
            }
          }
        `,
      });

      return Promise.resolve(data.data.whoAmI);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Get integrations */
  public static async getIntegrations(): Promise<Integration[]> {
    try {
      const { data } = await http.graphql<any>({
        query: gql`
          query {
            integrations {
              _id
              name
              enable
              prefix
              logoUrl
              direction
              webSiteUrl
              description
              connectUrl
              connected
              twoWaySync
            }
          }
        `,
      });

      return Promise.resolve(data.data.integrations);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
